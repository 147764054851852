import React, { useCallback } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'
import {
  faFacebook,
  faInstagram,
  faLinkedinIn,
  faWhatsapp,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons'
import clsx from 'clsx'
import axios from 'axios'

import * as styles from '../styles/components/Footer.module.scss'
import footerBg from '../images/footerBg.jpg'

import { Button } from './Button'
import { Divider } from './Divider'
import { useBoolean, useInput } from 'react-hanger'

const contactFormUrl = `https://api-serverless.roberty.app/v1/website/createBitrixLead`

const contacts = [
  {
    href: `mailto:melhoria@valorebrasil.com.br`,
    text: (
      <>
        melhoria@
        <br />
        valorebrasil.com.br
      </>
    ),
    icon: faEnvelope,
  },
  {
    href: `https://api.whatsapp.com/send?phone=5516993982007`,
    text: `(16) 99398-2007`,
    icon: faWhatsapp,
  },
  {
    href: `tel:+551634416888`,
    text: `(16) 3441-6888`,
    icon: faPhone,
  },
]

const socials = [
  {
    href: `https://br.linkedin.com/company/valore-brasil---controladoria-de-resultados`,
    icon: faLinkedinIn,
  },
  {
    href: `https://pt-br.facebook.com/ValoreBrasil`,
    icon: faFacebook,
  },
  {
    href: `https://www.instagram.com/valorebrasil`,
    icon: faInstagram,
  },
  {
    href: `https://www.youtube.com/channel/UC9QDps1MGtw_M_7UUeVpwqg`,
    icon: faYoutube,
  },
]

const ContactIcons: React.FC = () => (
  <div className="row">
    {contacts.map((contact, index) => (
      <div key={index} className="col-md mb-3 mb-lg-0">
        <a
          className={styles.contactItem}
          href={contact.href}
          rel="noopener"
          target={contact.href.indexOf(`http`) !== -1 ? `_blank` : undefined}
        >
          <div className={styles.contactIcon}>
            <FontAwesomeIcon icon={contact.icon} />
          </div>
          <span>{contact.text}</span>
        </a>
      </div>
    ))}
  </div>
)

const SocialIcons: React.FC = () => (
  <>
    {socials.map((social, index) => (
      <a
        key={index}
        className={clsx([`ml-3`, styles.socialIcon])}
        href={social.href}
        target="_blank"
        rel="noopener"
      >
        <FontAwesomeIcon icon={social.icon} />
      </a>
    ))}
  </>
)

export const Footer: React.FC = () => {
  const name = useInput(``)
  const phone = useInput(``)
  const email = useInput(``)
  const message = useInput(``)
  const loading = useBoolean(false)

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      loading.setTrue()
      try {
        await axios.post(contactFormUrl, {
          title: `[SGI - Melhoria de projeto] - ${name.value}`,
          name: name.value,
          phone: phone.value,
          email: email.value,
          description: message.value,
        })
        name.clear()
        phone.clear()
        email.clear()
        message.clear()
        alert(
          `Sua mensagem foi enviada com sucesso! Aguarde, em breve entraremos em contato com você.`
        )
      } catch (error) {
        console.error(error)
        alert(
          `Houve um erro ao enviar sua mensagem. Por favor, tente novamente mais tarde.`
        )
      } finally {
        loading.setFalse()
      }
    },
    [name.value, phone.value, email.value, message.value]
  )

  return (
    <footer
      className={styles.footer}
      style={{ backgroundImage: `url(${footerBg})` }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-6 mb-5 mb-lg-0">
            <h1>Vamos conversar?</h1>
            <p className="mb-3">
              Você pode entrar em contato com a gente preenchendo este
              formulário, ou através de uma dessas opções:
            </p>
            <ContactIcons />
          </div>

          <div className="col-lg-6">
            <form id="contato" onSubmitCapture={handleSubmit}>
              <input
                name="name"
                type="text"
                placeholder="Seu nome:"
                required={true}
                value={name.value}
                onChange={name.onChange}
                disabled={loading.value}
              />
              <input
                name="phone"
                type="tel"
                placeholder="Telefone (com WhatsApp):"
                required={true}
                value={phone.value}
                onChange={phone.onChange}
                disabled={loading.value}
              />
              <input
                name="email"
                type="email"
                placeholder="Seu e-mail (opcional):"
                value={email.value}
                onChange={email.onChange}
                disabled={loading.value}
              />
              <textarea
                name="message"
                placeholder="Como podemos ajudar?"
                required={true}
                value={message.value}
                onChange={message.onChange}
                disabled={loading.value}
              />
              <Button type="submit" disabled={loading.value}>
                Enviar
              </Button>
            </form>
          </div>
        </div>

        <Divider className="mt-5 mb-4" />

        <div className="row">
          <div className="col">
            <small>
              &copy; 2021 | Valore Brasil - Todos os direitos reservados.
            </small>
          </div>
          <div className="col-auto">
            <SocialIcons />
          </div>
        </div>
      </div>
    </footer>
  )
}
