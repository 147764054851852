import React from 'react'
import Slick from 'react-slick'
import clsx from 'clsx'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import * as styles from '../styles/components/Slider.module.scss'

import banner1 from '../images/banner1.jpg'
import { Button } from './Button'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

const slides = [
  {
    title: <>NORMAS DE GESTÃO ISO</>,
    description: (
      <>
        Vamos diagnosticar, medir o nível de aderência, e implementar as normas
        de gestão ISO (9001 / 14001 / 45001 / OUTRAS) em sua organização.
      </>
    ),
    backgroundUrl: banner1,
    sameOrigin: true,
    link: `/#contato`,
    linkText: `Fale Conosco`,
  },
]

export const Slider: React.FC = () => {
  return (
    <section className={styles.slider}>
      <Slick
        className={styles.slick}
        adaptiveHeight={true}
        infinite={true}
        autoplay={false}
        slidesToShow={1}
        slidesToScroll={1}
        dots={true}
        dotsClass={clsx([`slick-dots`, styles.slickDots])}
      >
        {slides.map((slide, index) => (
          <div key={index} className={styles.slideContainer}>
            <div
              className={styles.slideBackground}
              style={{ backgroundImage: `url(${slide.backgroundUrl})` }}
            />
            <div className={styles.slide}>
              <div className="container">
                <h2>{slide.title}</h2>
                <p>{slide.description}</p>

                {slide.sameOrigin ? (
                  <AnchorLink to={slide.link}>
                    <Button>{slide.linkText}</Button>
                  </AnchorLink>
                ) : (
                  <a href={slide.link}>
                    <Button>{slide.linkText}</Button>
                  </a>
                )}
              </div>
            </div>
          </div>
        ))}
      </Slick>
    </section>
  )
}
