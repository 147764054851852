import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

import * as styles from '../styles/components/Header.module.scss'
import logo from '../images/valore-logo.png'
import logoWhite from '../images/valore-logo-white.png'
import { Button } from './Button'

const items = [
  {
    text: `Serviços`,
    sameOrigin: true,
    href: `/#servicos`,
  },
  {
    text: `Cases de Sucesso`,
    sameOrigin: true,
    href: `/#cases-sucesso`,
  },
  {
    text: `Quem somos`,
    sameOrigin: true,
    href: `/#quem-somos`,
  },
  // {
  //   text: `Clientes`,
  //   sameOrigin: false,
  //   href: `https://valoremelhoria.com.br/#b8673`
  // },
  {
    text: `Blog`,
    sameOrigin: false,
    href: `https://blog.valoremelhoria.com.br/blog/`,
  },
  // {
  //   text: `Eventos`,
  //   sameOrigin: false,
  //   href: `https://valoremelhoria.com.br/eventosepalestras`,
  // },
]

export const Header: React.FC = () => {
  const [sticky, setSticky] = useState(false)

  const onScroll = () => {
    setSticky(window.scrollY > 0)
  }

  const onContactClick = () => {
    const elem: HTMLInputElement = document.querySelector(
      `form#contato input[name="name"]`
    )!
    elem.focus()
  }

  useEffect(() => {
    window.addEventListener(`scroll`, onScroll)
    return () => {
      window.removeEventListener(`scroll`, onScroll)
    }
  }, [])

  return (
    <header
      className={clsx({
        [styles.header]: true,
        [styles.headerSticky]: sticky,
      })}
    >
      <div className="container">
        <div
          className={clsx([
            `row`,
            `justify-content-between`,
            `align-items-center`,
          ])}
        >
          <div className="col-auto">
            <AnchorLink to="/">
              <img className={styles.logo} src={logo} alt="Valore Brasil" />
              <img
                className={styles.logoWhite}
                src={logoWhite}
                alt="Valore Brasil"
              />
            </AnchorLink>
          </div>

          <div className="col-auto">
            <nav className="main-menu">
              {items.map((item, index) =>
                item.sameOrigin ? (
                  <AnchorLink
                    key={index}
                    className={clsx([
                      styles.menuItem,
                      `d-none`,
                      `d-lg-inline-block`,
                    ])}
                    to={item.href}
                  >
                    {item.text}
                  </AnchorLink>
                ) : (
                  <a
                    key={index}
                    className={clsx([
                      styles.menuItem,
                      `d-none`,
                      `d-lg-inline-block`,
                    ])}
                    href={item.href}
                    target="_blank"
                    rel="noopener"
                  >
                    {item.text}
                  </a>
                )
              )}

              <AnchorLink to="/#contato" onAnchorLinkClick={onContactClick}>
                <Button className="ml-2">Entre em contato</Button>
              </AnchorLink>
            </nav>
          </div>
        </div>
      </div>
    </header>
  )
}
