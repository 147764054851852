import React from 'react'
import clsx from 'clsx'

import '../styles/global.scss'
import * as styles from '../styles/pages/index.module.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faGlobe,
  faUserCog,
  faTasks,
  faSitemap,
} from '@fortawesome/free-solid-svg-icons'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

import { SEO } from '../components/SEO'
import { Divider } from '../components/Divider'
import { Header } from '../components/Header'
import { Slider } from '../components/Slider'
import { SectionTitle } from '../components/SectionTitle'
import { AboutUs } from '../components/AboutUs'
import { Statistics } from '../components/Statistics'
import { Offices } from '../components/Offices'
import { Footer } from '../components/Footer'
import { Button } from '../components/Button'
import { SuccessCases } from '../components/SuccessCases'

const Home: React.FC = () => {
  return (
    <main>
      <SEO title="Consultoria em gestão de projeto - Valore Brasil" />
      <Header />
      <Slider />

      <section id="servicos">
        <div className="pt-3 pb-5">
          <div className="container">
            <SectionTitle
              title="Nosso Propósito"
              description="Buscar soluções para implementar as normas de gestão ISO em nossos clientes através de um diagnóstico preciso, ferramentas que medem o nível de aderência aos requisitos normativos, e implementando ações de padronização e melhoria de processos."
            />
          </div>
          <div className={clsx([`container`, styles.containerLarge])}>
            <div className="row mt-3">
              <div
                className={clsx([`col-lg-3 mb-5 mb-lg-0`, styles.stepsItem])}
              >
                <div className={styles.stepCircle}>
                  <FontAwesomeIcon icon={faTasks} />
                </div>
                <b>Diagnóstico de Aderência às Normas</b>
                <p>
                  Faremos um diagnóstico preciso com base na norma desejada, e
                  forneceremos o nível de aderência da sua organização com
                  relação aos itens normativos, bem como um plano de ação
                  estruturado que direcione os seus esforços na busca pela
                  obtenção do certificado ISO.
                </p>
              </div>

              <div
                className={clsx([`col-lg-3 mb-5 mb-lg-0`, styles.stepsItem])}
              >
                <div className={styles.stepCircle}>
                  <FontAwesomeIcon icon={faSitemap} />
                </div>
                <b>Auditoria Interna</b>
                <p>
                  Seremos a empresa “parceira” de sua organização nessa tarefa
                  normativa, e de suma importância. Nossa metodologia consiste
                  na execução ponta-a-ponta de auditoria dos processos
                  (planejamento, execução e encerramento).
                </p>
              </div>

              <div
                className={clsx([`col-lg-3 mb-5 mb-lg-0`, styles.stepsItem])}
              >
                <div className={styles.stepCircle}>
                  <FontAwesomeIcon icon={faGlobe} />
                </div>
                <b>Implementação de Normas de Gestão ISO</b>
                <p>
                  Nossa equipe irá te acompanhar desde o diagnóstico até a
                  implementação de todas as ações para obtenção do certificado,
                  com foco em padronização e melhoria de processos sustentáveis.
                </p>
              </div>
              <div
                className={clsx([`col-lg-3 mb-2 mb-lg-0`, styles.stepsItem])}
              >
                <div className={styles.stepCircle}>
                  <FontAwesomeIcon icon={faUserCog} />
                </div>
                <b>Treinamentos</b>
                <p>
                  Forneceremos para sua organização, treinamentos de introdução
                  e interpretação de normas de gestão ISO, conforme tema
                  específico (
                  <a
                    href="https://blog.valoremelhoria.com.br/2021-05-20-as-normas-de-gest%C3%A3o-da-iso-e-sua-associa%C3%A7%C3%A3o-com-o-ciclo-pdca/"
                    target="_blank"
                    rel="noopener"
                    className={styles.innerTextLink}
                  >
                    9001
                  </a>{' '}
                  /{' '}
                  <a
                    href="https://blog.valoremelhoria.com.br/2021-05-20-desenvolvimento-sustent%C3%A1vel-iso-14001-2015/"
                    target="_blank"
                    rel="noopener"
                    className={styles.innerTextLink}
                  >
                    14001
                  </a>
                  /{' '}
                  <a
                    href="https://blog.valoremelhoria.com.br/2021-05-20-iso-45001-sistemas-de-gest%C3%A3o-de-sa%C3%BAde-e-seguran%C3%A7a-ocupacionais/"
                    target="_blank"
                    rel="noopener"
                    className={styles.innerTextLink}
                  >
                    45001
                  </a>{' '}
                  / 55001 / 31000 / outras).
                </p>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row mt-5 align-items-center justify-content-center">
              <a
                href="https://valoremelhoria.com.br/#block8421"
                target="_blank"
                rel="noopener"
              >
                <Button>Conheça todos os nossos serviços</Button>
              </a>
            </div>
          </div>
        </div>

        <div className="container">
          <Divider />
        </div>

        <div className="py-5">
          <div className={clsx([`container`, styles.containerLarge])}>
            <div className="row align-items-center">
              <div className="col-lg-4 mb-3 mb-lg-0 pr-4">
                <h2 className="mb-3" style={{ color: '#1fa9e2' }}>
                  Metodologia Utilizada
                </h2>
                <p className="mb-3">
                  Conheça agora os 04 principais passos de nossa metologia, para
                  implementar a mudança na sua organização.
                </p>

                <AnchorLink to={'/#contato'} className="hidden-mobile">
                  <Button>Dúvidas? Fale com a gente</Button>
                </AnchorLink>
              </div>
              <div className={clsx(['col-lg-8 pl-4', styles.boxMethodology])}>
                <ul className={styles.methodology}>
                  <li>
                    <span>
                      <small>#</small>1
                    </span>
                    <div>
                      <h3>Planejamento</h3>
                      <p>
                        A fase do planejamento é um processo comercial. Neste
                        momento ainda não houve a contratação do produto. O
                        objetivo desta etapa é alinhar todas as expectativas do
                        projeto e os objetivos junto ao contratante.
                        <AnchorLink to={'/#contato'}>
                          {' '}
                          Agende aqui essa etapa sem compromisso.
                        </AnchorLink>
                      </p>
                    </div>
                  </li>
                  <li>
                    <span>
                      <small>#</small>2
                    </span>
                    <div>
                      <h3>Diagnóstico</h3>
                      <p>
                        Nessa etapa, iremos coletar informações através de
                        entrevistas com os pontos focais de cada processo,
                        fazendo perguntas e tomando nota das constatações. É
                        neste momento que serão registradas as “conformidades”,
                        oportunidades de melhorias, e as não conformidades (caso
                        sejam identificadas), ou seja, situações que ocorreram
                        em desacordo com as normas, leis, processo e
                        procedimento padronizado.
                      </p>
                    </div>
                  </li>
                  <li>
                    <span>
                      <small>#</small>3
                    </span>
                    <div>
                      <h3>Análise e Melhoria</h3>
                      <p>
                        Após concluída a execução do diagnóstico, será
                        apresentado um relatório que demonstra o nível de
                        maturidade de sua organização com relação aos requisitos
                        normativos, bem como um plano de ação que direcione os
                        esforços para obtenção da certificação, dentro do prazo
                        pretendido pelo cliente.
                      </p>
                    </div>
                  </li>
                  <li>
                    <span>
                      <small>#</small>4
                    </span>
                    <div>
                      <h3>Implementação</h3>
                      <p>
                        Muito importante para a melhoria não ficar apenas na
                        teoria. A Valore utiliza as técnicas de GMO do instituto
                        HCMI para engajar e “puxar” as mudanças para a empresa
                        realmente implantar o que foi aprovado. Com reuniões
                        periódicas unem-se os agentes das mudanças (comitê) para
                        reportarem às necessidades ou as dificuldades da
                        implantação.
                      </p>
                    </div>
                  </li>
                  <li className="pt-4 justify-content-center hidden-mobile-up">
                    <AnchorLink to={'/#contato'}>
                      <Button>Dúvidas? Fale com a gente</Button>
                    </AnchorLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <SuccessCases />
      <AboutUs />
      <Statistics />
      <div className="container">
        <Divider />
      </div>

      <Offices />
      <Footer />
    </main>
  )
}

export default Home
